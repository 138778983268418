//import logoLight from "@/images/logo.png";
import logoLight from "@/images/logo-eec.svg";
import logoDark from "@/images/logo-eec.svg";

export const LogoImage = {
  light: logoLight,
  dark: logoDark,
};

export const NavLinks = [
  {
    name: "Home",
    url: "/",
    /*
  subItems: [
    {
      name: "Home 01",
      url: "/",
    },
    {
      name: "Home 02",
      url: "/index-2",
    },
    {
      name: "Home 03",
      url: "/index-3",
    },
    ],
     */
  },
  {
    name: "About",
    url: "/about",
  },
  {
    name: "Services",
    url: "/service",
    /*
    subItems: [
      {
        name: "Services",
        url: "/service",
      },
      {
        name: "Service Details",
        url: "/service-details",
      },
    ],
     */
  },
  {
    name: "Case Study",
    url: "/portfolio",
    /*
    subItems: [
      {
        name: "Portfolio 01",
        url: "/portfolio",
      },
      {
        name: "Portfolio Details",
        url: "/portfolio-details",
      },
    ],
     */
  },
  /*
  {
    name: "Blog",
    url: "/blog",
    subItems: [
      {
        name: "Blog 01",
        url: "/blog",
      },
      {
        name: "Blog 02",
        url: "/blog-2",
      },
      {
        name: "Blog 03",
        url: "/blog-3",
      },
      {
        name: "Blog Details",
        url: "/blog-single",
      },
    ],
  },
     */
  /*
  {
    name: "Contact",
    url: "/contact",
  },
   */
];

/*
import sliderOne01 from "@/images/slider/a.jpg";
import sliderOne02 from "@/images/slider/b.jpg";

export const SliderOneData = [
  {
    image: sliderOne01,
    subTitle: "WELCOME TO EEC",
    title: "ENGINEERS A BETTER WORLD \nTHROUGH THE SUSTAINABLE USE OF ENERGY",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
  {
    image: sliderOne02,
    subTitle: "WELCOME TO EEC",
    title: "LOREM\nIPSUM ORO\nASNEST.",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
];
*/

import sliderTwo01 from "@/images/slider/a.jpg";
import sliderTwo02 from "@/images/slider/b.jpg";
import sliderTwo03 from "@/images/slider/d.jpg";

export const SliderTwoData = [
  {
    image: sliderTwo01,
    subTitle: "Engineers a better world",
    title: "FUTURE GRID STRATEGIES \nFOR UTILITIES",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
  {
    image: sliderTwo02,
    subTitle: "Engineers a better world",
    title: "RENEWABLES & DISTRIBUTED\nENERGY RESOURCE PLANNING",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
  {
    image: sliderTwo03,
    subTitle: "Engineers a better world",
    title: "ADOPTION OF NEW TECHNOLOGIES",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
];

/*
import sliderThree01 from "@/images/slider/3_1.jpg";
import sliderThree02 from "@/images/slider/3_2.jpg";

export const SliderThreeData = [
  {
    image: sliderThree01,
    subTitle: "WELCOME TO MEIPALY AGENCY",
    title: "SMART WEB\n DESIGN AGENCY.",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
  {
    image: sliderThree02,
    subTitle: "WELCOME TO MEIPALY AGENCY",
    title: "SMART WEB\n DESIGN AGENCY.",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
];
*/

export const ContactInfosBlock = {
  subTitle: "our offices",
  title: "offices near you",
  description:
    "We are committed to providing our customers with exceptional service while \n offering our employees the best training.",
};

export const ContactInfosList = [
  {
    title: "austin",
    infos: [
      {
        name: "22 Texas West Hills",
      },
      {
        name: "needhelp@meipaly.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
  {
    title: "boston",
    infos: [
      {
        name: "5 Federal Street Boston",
      },
      {
        name: "needhelp@meipaly.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
  {
    title: "new york",
    infos: [
      {
        name: "8th Broklyn New York",
      },
      {
        name: "needhelp@meipaly.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
  {
    title: "baltimore",
    infos: [
      {
        name: "3 Lombabr 50 baltimore",
      },
      {
        name: "needhelp@meipaly.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
];

export const ContactFormTitle = {
  subTitle: "Contact with us",
  title: "write us a message",
  description:
    "We are committed to providing our customers with exceptional service while \n      offering our employees the best training. ",
};

import blogImage1 from "@/images/blog/1.jpg";
import blogImage2 from "@/images/blog/2.jpg";
import blogImage3 from "@/images/blog/3.jpg";
import blogImage4 from "@/images/blog/4.jpg";
import blogImage5 from "@/images/blog/5.jpg";
import blogImage6 from "@/images/blog/6.jpg";

export const BlogData = [
  {
    title: "basic rules of running web agency business",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage1,
  },
  {
    title: "Become the best sale marketer",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage2,
  },
  {
    title: "Introducing latest mopaly features",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage3,
  },
  {
    title: "a deep understanding of our audience",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage4,
  },
  {
    title: "We build and activate brands insight",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage5,
  },
  {
    title: "experiences that connect with people",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage6,
  },
];

import blogImageTwo1 from "@/images/blog/14.jpg";
import blogImageTwo2 from "@/images/blog/15.jpg";

export const BlogTwoData = [
  {
    title:
      "Dynamically procrastinate unique vortals with global best practices.",
    date: "20 nov",
    url: "/blog-single",
    image: blogImageTwo1,
  },
  {
    title: "Become the best sale marketer",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage2,
  },
  {
    title: "Introducing latest mopaly features",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage3,
  },
  {
    title: "a deep understanding of our audience",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage4,
  },
  {
    title: "We build and activate brands insight",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage5,
  },
  {
    title:
      "Holisticly conceptualize backend scenarios via accurate technologies.",
    date: "20 nov",
    url: "/blog-single",
    image: blogImageTwo2,
  },
  {
    title: "experiences that connect with people",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage6,
  },
];

export const BlogHomeSection = {
  subTitle: "our news & articles",
  title: "latest blog posts",
  text:
    "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
};

import blogS1 from "@/images/blog/11.jpg";
import blogS2 from "@/images/blog/12.jpg";
import blogS3 from "@/images/blog/13.jpg";

export const BlogSidebarPost = [
  {
    title: "basic rules of running web agency",
    image: blogS1,
    url: "/blog-single",
  },
  {
    title: "basic rules of running web agency",
    image: blogS2,
    url: "/blog-single",
  },
  {
    title: "basic rules of running web agency",
    image: blogS3,
    url: "/blog-single",
  },
];

import commentImage1 from "@/images/blog/9.jpg";
import commentImage2 from "@/images/blog/10.jpg";

export const BlogComments = [
  {
    image: commentImage1,
    name: "David Martin",
    data: "20 Nov, 2018 - 4:00 pm",
    content:
      "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et not the aliquet.",
  },
  {
    image: commentImage2,
    name: "Jessica Brown",
    data: "20 Nov, 2018 - 4:00 pm",
    content:
      "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et not the aliquet.",
  },
];

import portfolio01 from "@/images/portfolio/1.jpg";
import portfolio02 from "@/images/portfolio/2.jpg";
import portfolio03 from "@/images/portfolio/3.jpg";
import portfolio04 from "@/images/portfolio/4.jpg";
import portfolio05 from "@/images/portfolio/5.jpg";
import portfolio06 from "@/images/portfolio/6.jpg";

export const PortfolioData = [
  {
    title: "Jemena DER hosting capacity",
    categories: ["all", "graphic"],
    image: portfolio01,
    url: "/portfolio-details?page=jemena_der",
  },
  {
    title: "AusNet Services Project EDGE",
    categories: ["all", "graphic"],
    image: portfolio02,
    url: "/portfolio-details?page=ausnet_edge",
  },
  {
    title: "AusNet Services Flexible Export",
    categories: ["all", "graphic"],
    image: portfolio03,
    url: "/portfolio-details?page=ausnet_export",
  },
  {
    title: "Dynamic Electric Vehicle Charging",
    categories: ["all", "graphic"],
    image: portfolio04,
    url: "/portfolio-details?page=ausnet_dynamic",
  },
  {
    title: "Jemena ADMS Implementation",
    categories: ["all", "graphic"],
    image: portfolio05,
    url: "/portfolio-details?page=jemena_adms",
  },
];

export const PortfolioFilters = [
  { name: "all" },
  { name: "graphic" },
  { name: "branding" },
  { name: "marketing" },
  { name: "logos" },
];

import portfolioD01 from "@/images/portfolio/7.jpg";
import portfolioD02 from "@/images/portfolio/8.jpg";

export const PortfolioDetailsData = {
  jemena_der: {
    gallery: [{ image: portfolioD01 }, { image: portfolioD02 }],
    title: "Jemena DER hosting capacity project",
    text:
      "The trial was an alliance between Jemena, AusNet Services, and University of NSW, Sydney. " +
      "It was supported by the Australian Renewable Energy Agency (ARENA), as part of ARENA’s Advancing Renewables Program, and Hume City Council. " +
      "To better meet the increasing energy demand, maintain quality and reliability of supply, and utilise excess power generated by rooftop solar during the day, "+
      "this trial introduced three intelligent control technologies on the Low Voltage trial sites: Dynamic Phase Shifting, Dynamic Power Compensation & Balancing, and 100kW/200kWh Battery Energy Storage System.",
    client: "ARENA, Jemena, AusNet Services, and University of NSW, Sydney",
    categories: [
      {
        name: "Lead Funding Applicant",
        url: "#",
      },
      {
        name: "Project Delivery Technical Director",
        url: "#",
      },
    ],
    date: "Jan 2019 to July 2021",
    url: 'https://jemena.com.au/about/innovation/creating-solar-friendly-neighbourhoods-trial'
  },
  ausnet_edge: {
    gallery: [{ image: portfolioD01 }, { image: portfolioD02 }],
    title: "AusNet SErvices Energy Demand & Generation Exchange (EDGE) PROJECT",
    text:
      "Project EDGE seeks to demonstrate a proof of-concept DER Marketplace which enables efficient, secure, coordinated DER aggregation, delivering both wholesale and local network support services at the grid edge. "+
      "AEMO, AusNet Services and Mondo have partnered, with support from the Australian Renewable Energy Agency (ARENA), to demonstrate how a Two-Sided Market and power system could work. This builds on the theory explored in the Open Energy Networks process (OpEN), which identified how AEMO and DNSPs could collaborate to deliver a “hybrid model”",
    client: "ARENA, AEMO, AusNet Services, and Mondo Power",
    categories: [
      {
        name: "Distribution Engineering Subject Matter Expert",
        url: "#",
      },
    ],
    date: "Mid 2020 to Mid 2023",
    url: "https://aemo.com.au/en/initiatives/major-programs/nem-distributed-energy-resources-der-program/der-demonstrations/project-edge"
  },
  ausnet_export: {
    gallery: [{ image: portfolioD01 }, { image: portfolioD02 }],
    title: "AusNet Services Flexible Export Project",
    text: "Led by SA Power Networks and participated by AusNet Services and vendors and supported by ARENA, this Flexible Exports for Solar PV Trial project aims to produce a flexible connection option for solar PV systems,"+
      " so customers don’t have to limit electricity export to permanent zero or near-zero in congested areas.",
    client: "ARENA, SA Power Networks, AusNet Services, SwitchDin, Fronius, SMA and Solar Edge",
    categories: [
      {
        name: "Distribution Engineering Subject Matter Expert",
        url: "#",
      },
    ],
    date: "Jul 2020 to Mid 2022",
    url: 'https://arena.gov.au/projects/sa-power-networks-flexible-exports-for-solar-pv-trial/'
  },
  ausnet_dynamic: {
    gallery: [{ image: portfolioD01 }, { image: portfolioD02 }],
    title: "AusNet Services Dynamic Electric Vehicle Charging (EV Grid) Project",
    text:
      "EV Grid project is a trial, supported by ARENA, to allow electricity distributors to control residential EV charging rate to match the available network capacity. "+
      "The project is led by Jemena, with project partners AusNet Services, Evoenergy, TasNetworks, United Energy and JET Charge to deliver the EV trial, recruiting 176 participants across Victoria, Tasmania and the ACT.",
    client: "ARENA, Jemena, AusNet Services, United Energy, Evoenergy, TasNetworks and JET Charge",
    categories: [
      {
        name: "Lead funding applicant (for Jemena)",
        url: "#",
      },
      {
        name: " Distribution Engineering Subject Matter Expert (for AusNet Services)",
        url: "#",
      },
    ],
    date: "Jan 2021 to Dec 2022",
    url: 'https://jemena.com.au/about/newsroom/media-release/2020/new-trial-prepares-electricity-grid-for-electric-v'
  },
  jemena_adms: {
    gallery: [{ image: portfolioD01 }, { image: portfolioD02 }],
    title: "Jemena Advanced Distribution Management System (ADMS) Implementation Project",
    text: "The ADMS implementation is part of the Jemena Real-Time System (RTS) Upgrade Project."+
      " The project implements the latest ADMS functionalities in the SCADA/DMS OT system used in the Jemena Electricity Networks. " +
      "The ADMS functions include:",
    client: "Jemena, OSI International",
    categories: [
      {
        name: "Lead Asset Management Representative in project team",
        url: "#",
      },
    ],
    date: "Jan 2018 to Dec 2022",
    url: "https://www.osii.com/news-and-events/media-coverage.asp?display=detail&id=297"
  }
};


export const ServiceHomeOneData = {
  subTitle: "Welcome to Eagles Engineering Consultants",
  title: "WE FACILITATE SUSTAINABLE USE OF ENERGY",
  text:
    "We provide consulting services that facilitates sustainable use of renewable energy",
};

export const ServicePostData = [
  {
    title: "Utility Consulting",
    text:
      "Strategic advice and technical assessments",
    iconName: "mei-settings",
    url: "/service-details?page=0",
  },
  {
    title: "Industry Consulting",
    text:
      "Distributed Energy Resource (DER) proposals, technical advice and feasibility studies",
    iconName: "mei-pie-chart",
    url: "/service-details?page=1",
  },
  {
    title: "Technical Assessment",
    text:
      "New DER technology assessment and adaptation for Australian market",
    iconName: "mei-transfer",
    url: "/service-details?page=2",
  },
];
export const ServiceDetailsData = [
  {
    title: 'Utility Consulting',
    data: [
      {
        title: 'Provision of strategic advice',
        items: [
          'Future grid strategies to prepare electricity networks to meet the future challenges of climate change, sustainability, affordability and customer centricity through the use of advanced technologies and innovations',
          'Roadmap for the management of Distributed Energy Resources (DER)',
          'Use of smart metering technologies and advanced data analytics applications'
        ]
      }
    ]
  },
  {
    title: 'Industry Consulting',
    data: [
      {
        title: 'Develop proposals for DER connections',
        items: [
          'Identify optimal locations for the siting of DERs to access multiple revenue streams',
          'Perform network studies',
        ]
      }
    ]
  },
  {
    title: '',
    data: [
      {
        title: '',
        items: [
        ]
      }
    ]
  },
];

export const ServiceHomeTwoData = {
  subTitle: "welcome to smart meipaly web agency",
  title: "We design digital products that \n help grow businesses.",
  text:
    "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
};

export const ServiceHomeThreeData = {
  subTitle: "Services we are offering",
  title: "Our Services",
  text:
    "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
};
/*
export const ServicePostTwoData = {
  sectionContent: {
    title: "Lorem ipsum dolor sit amet\nConsectetur adipiscing elit",
    subTitle: "Services we are offering",
    text:
      "We are committed to providing our customers with exceptional service \n while offering our employees the best training.",
  },
  posts: [
    {
      title: "Strategic Advice",
      iconName: "mei-web-design",
      url: "/service-details",
    },
    {
      title: "Technical Assessment",
      iconName: "mei-computer-graphic",
      url: "/service-details",
    },
    {
      title: "Develop Proposals",
      iconName: "mei-development-1",
      url: "/service-details",
    },
    {
      title: "Provision of Strategic Advice",
      iconName: "mei-development",
      url: "/service-details",
    },
    {
      title: "Facilitate the Adoption",
      iconName: "mei-app-development",
      url: "/service-details",
    },
  ],
};
*/

import serviceOne01 from "@/images/home_1/service1.jpg";
import serviceOne02 from "@/images/home_1/service2.jpg";
import serviceOne03 from "@/images/home_1/8.jpg";

// service page
export const ServicePostThreeData = {
  sectionContent: {
    title: "Let’s create something",
    subTitle: "what we do",
    text:
      "We are committed to providing our customers with exceptional service \n while offering our employees the best training.",
  },
  posts: [
    {
      title: "Utility Consulting",
      image: serviceOne01,
      url: "/service-details?page=0",
    },
    {
      title: "Industry Consulting",
      image: serviceOne02,
      url: "/service-details?page=1",
    },
    {
      title: "TECHNOLOGY ASSESSMENTS",
      image: serviceOne03,
      url: "/service-details?page=2",
    },
  ],
};

export const FunfactData = [
  {
    title: "Projects Completed",
    countNumber: 705,
  },
  {
    title: "Active Clients",
    countNumber: 480,
  },
  {
    title: "Cups of Coffee",
    countNumber: 626,
  },
  {
    title: "Happy Clients",
    countNumber: 774,
  },
];

import trustClient01 from "@/images/home_1/4.jpg";

export const TrustClientData = {
  image: trustClient01,
  title: "We are trusted by more than 8900 clients",
  text:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi-dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercita-tion ullamco laboris nisi ut aliquip ex ea commodo.",
  url: "/about",
};

import ClientCarousel01 from "@/images/client/arena-logo.svg";
import ClientCarousel02 from "@/images/client/jemena-logo.svg";
import ClientCarousel03 from "@/images/client/logo_ausnet.png";
import ClientCarousel04 from "@/images/client/osii-logo.png";
import ClientCarousel05 from "@/images/client/5.png";

export const ClientCarouselData = {
  sectionContent: {
    title: "they trust us",
    subTitle: "our clients",
    text:
      "We are committed to providing our customers with exceptional service \n while offering our employees the best training.",
  },
  items: [
    {
      url: "#",
      image: ClientCarousel01,
    },
    {
      url: "#",
      image: ClientCarousel02,
    },
    {
      url: "#",
      image: ClientCarousel03,
    },
    {
      url: "#",
      image: ClientCarousel04,
    },
  ],
};

import aboutOne01 from "@/images/about/1.jpg";
import aboutOne02 from "@/images/about/about1.jpg";

export const AboutOneData = {
  sectionContent: {
    title: "Always deliver to meet commitments made to clients",
    subTitle: "get to know us",
  },
  gallery: [aboutOne01, aboutOne02],
  counter: {
    title: "Company Started",
    number: 2020,
  },
};

import team01 from "@/images/team/1.jpg";
import team02 from "@/images/team/2.jpg";
import team03 from "@/images/team/3.jpg";
import team04 from "@/images/team/4.jpg";
import team05 from "@/images/team/5.jpg";

export const TeamOneData = {
  sectionContent: {
    title: "expert people",
    subTitle: "meet the team",
    text:
      "We are committed to providing our customers with exceptional service \n while offering our employees the best training.",
  },
  posts: [
    {
      image: team01,
      name: "Herman Fisher",
      designation: "General Manager",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team02,
      name: "Charlie Kennedy",
      designation: "Brand Designer",
      url: "",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team03,
      name: "Helena Adkins",
      designation: "Seinor Designer",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team04,
      name: "Virginia Farmer",
      designation: "Marketing Manager",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team05,
      name: "Philip Hansen",
      designation: "Co Founder",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
  ],
};

import video01 from "@/images/about/3.jpg";

export const VideoOneData = {
  sectionContent: {
    title: "Make amazing websites without touching cod",
    subTitle: "the only design you need",
    text:
      "Tincidunt elit magnis nulla facilisis sagittis maecenas. Sapien nunc amet ultrices, dolores sit ipsum velit purus aliquet, massa fringilla leo orci. Lorem ipsum dolors sit amet elit magnis amet ultrices purus aliquet.",
  },
  video: {
    image: video01,
    ID: "y2Eqx6ys1hQ",
    title: "Watch Video",
  },
};

export const SubscribeFormData = {
  sectionContent: {
    title: "Subscribe us",
    subTitle: "don’t miss out our latest updates",
  },
};

import testimonial01 from "@/images/about/ranjan.jpg";
import testimonial02 from "@/images/about/john.jpg";
import testimonial03 from "@/images/about/jignesh.jpg";

export const TestimonialsOneData = {
  sectionContent: {
    subTitle: "our testimonials",
    title: "happy customers",
  },
  posts: [
    {
      name: "Ranjan Vaidya",
      designation: "Director",
      company: 'IAENGG Pty Ltd',
      image: testimonial01,
      content:
        "Thanks, Peter for your high-level review of a network protection setting report that IAEngg prepared for one of our utility clients. As ever, it was great working with you. Your expertise in the field helped us immensely in our quality management. Looking forward to work together more.",
      date: "01 oct, 2021",
    },
    {
      name: "John Theunissen",
      designation: "Head of Emerging Markets",
      company: 'AusNet Services',
      image: testimonial02,
      content:
        "Peter provides specialist engineering consulting skills in our ARENA funded DER marketplace project EDGE and others, with specific application to DER integration and nascent “Distribution System Operator” foundation functionality. Peter’s in-depth knowledge of electricity distribution networks and their intricate complexities has been invaluable for us, especially in relation to working with various technology, academic and industry partners. His expertise in identifying, crafting and guiding solution options that are practical, implementable and project outcome focused has been a real advantage to our projects.",
      date: "25 oct, 2021",
    },
    {
      name: "Jignesh Patel",
      designation: "FEED Project Manager",
      company: 'Jemena',
      image: testimonial03,
      content:
        "I worked closely with Peter Wong on some technological innovation projects. In his role as a technical director in those projects, he was instrumental in conceiving the ideas, formalising them in investment proposals, developing technical solutions to the unique problems, and converting them in real implementation. His depth of technical knowledge was impressive and enthusiasm to pursue the solutions was contagious, but I was most impressed by his soft skills in persuading the management, collaborating with the stakeholders and contacts within the industry. I am sure he will continue to show guidance to the industry for years to come.",
      date: "25 sep, 2021",
    },
  ],
};

import featureTab01 from "@/images/home_1/c1.jpg";
import featureTab02 from "@/images/home_1/c2.jpg";
import featureTab03 from "@/images/home_1/c3.jpg";

export const FeatureTabData = {
  sectionContent: {
    title: "why choose us",
    subTitle: "our benefits",
    text:
      "Eagles Engineering Consultants have deep domain knowledge of electricity distribution engineering and the associated regulatory environment.",
  },
  posts: [
    {
      title: "Latest technology",
      content:
        "There are many variations of passages of lorem ipsum available, but the majority have  suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. ",
      image: featureTab01,
      lists: [
        {
          item: "Self-contained, state-of-the-art time clock",
        },
        {
          item: "Scalability of up to 500 employees per time clock",
        },
        {
          item: "The ability to connect up to 32 time clocks",
        },
        {
          item: "Employee self-enrollment",
        },
        {
          item: "Payroll integration",
        },
        {
          item: "Built-in backup camera to verify failed punches",
        },
      ],
    },
    {
      title: "Awesome Support",
      content:
        "There are many variations of passages of lorem ipsum available, but the majority have  suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. ",
      image: featureTab02,
      lists: [
        {
          item: "Self-contained, state-of-the-art time clock",
        },
        {
          item: "Scalability of up to 500 employees per time clock",
        },
        {
          item: "The ability to connect up to 32 time clocks",
        },
        {
          item: "Employee self-enrollment",
        },
        {
          item: "Payroll integration",
        },
        {
          item: "Built-in backup camera to verify failed punches",
        },
      ],
    },
    {
      title: "1 Click demo install",
      content:
        "There are many variations of passages of lorem ipsum available, but the majority have  suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. ",
      image: featureTab03,
      lists: [
        {
          item: "Self-contained, state-of-the-art time clock",
        },
        {
          item: "Scalability of up to 500 employees per time clock",
        },
        {
          item: "The ability to connect up to 32 time clocks",
        },
        {
          item: "Employee self-enrollment",
        },
        {
          item: "Payroll integration",
        },
        {
          item: "Built-in backup camera to verify failed punches",
        },
      ],
    },
  ],
};

export const ParallaxOneData = {
  iconName: "mei-team",
  title: "Great things in business are never done by one person.",
  specialText: "They’re done by a team of people.",
  text:
    "We are committed to providing our customers with exceptional service while \n offering our employees the best training.",
};

export const PortfolioHomeData = {
  sectionContent: {
    title: "Case Studies",
    subTitle: "our portfolio",
    text:
      "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
  },
};

import video02 from "@/images/home_1/3.jpg";

export const VideoTwoData = {
  sectionContent: {
    title: "Digital Experience",
    subTitle: "how do we works",
    text:
      "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
  },
  video: {
    title: "Watch Video",
    ID: "y2Eqx6ys1hQ",
    image: video02,
  },
};

import aboutTwo01 from "@/images/home_1/profile4.jpg";
import aboutTwo02 from "@/images/home_1/profile5b.jpg";

export const AboutTwoData = {
  sectionContent: {
    title: "We are passionate about sustainable use of energy",
    subTitle: "Engineers a better world",
    text:
      "Eagles Engineering Consultants (EEC) is set up to facilitate the sustainable use of energy by individuals, communities, companies and governments. EEC achieves this goal by drawing upon its extensive domain expertise in electricity distribution engineering and Distributed Energy Resources (DER) technologies in Australia and overseas.",
  },
  button: {
    label: "Learn More",
    url: "#",
  },
  gallery: [aboutTwo01, aboutTwo02],
};

import featureTwo01 from "@/images/home_1/5.jpg";

export const FeatureTwoData = {
  sectionContent: {
    title: "real experience",
    subTitle: "our core features",
    text:
      " We are committed to providing our customers with exceptional service while offering our employees the best training. ",
  },
  posts: [
    {
      title: "No Coding Skills Require",
      text:
        "There are many variations of passages of lorem ipsum available, but the majority have suffered.",
    },
    {
      title: "Online Documentation",
      text:
        "There are many variations of passages of lorem ipsum available, but the majority have suffered.",
    },
    {
      title: "SEO Optimized",
      text:
        "There are many variations of passages of lorem ipsum available, but the majority have suffered.",
    },
  ],
  image: {
    text: "Total design freedom \n for everyone.",
    path: featureTwo01,
  },
};

export const CallToActionTwoData = [
  {
    label: "View our Recent Work",
    url: "/portfolio",
  },
  {
    label: "Reqeust a free quote",
    url: "/contact",
  },
];
